import React, { Component, } from 'react';
import './App.css';
import { Link } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

const cdn = 'https://cdnwaxl.52zhongyi.net/';


class App extends Component {
  componentDidMount() {
    this.swiper = new Swiper('.banner-detail', {
      autoplay: true,
      direction: 'horizontal',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  renderSwiper() {
    const images = [
      cdn + "app/resource/banner1.jpg",
      cdn + "app/resource/banner-3.png",
    ];
    return (
      <div style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 50,
        position: 'relative',
        width: 750,
      }}>
        <div className="swiper-container banner-detail">
          <div className="swiper-wrapper">
            {(images).map((item, index) => {
              return <img
                style={{
                  width: 750,
                  height: 280,
                  objectFit: 'cover',
                }}
                alt=""
                src={item}
                key={index}
                className="swiper-slide"
              ></img>
            })
            }
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={styles.allPage}>
        <div style={styles.topPage}>
          <div style={styles.shu}>
            <div style={{ fontSize: 30, padding: 30 }}>恒透灸</div>
            {this.renderSwiper()}
            <div style={styles.shuflex}>
              <div style={{ fontSize: 22, padding: 10 }}>健康科普</div>
              <div style={{ height: 2, width: 20, background: '#C55936', marginBottom: 10, marginLeft: 10 }}>&nbsp;</div>
              <div style={{ fontSize: 18, paddingLeft: 10 }}>普及养生常识，提倡科学养生</div>
              <div style={{ fontSize: 18, paddingLeft: 10 }}>健康资讯、穴位查找、艾灸知识、中医课程、养生案例</div>
            </div>
          </div>
        </div>
        <div style={styles.bottomPage}>
          <div style={styles.shu}>
            <img style={{ width: 110, height: 110, paddingTop: 30, paddingBottom: 30 }} src={require('./assets/icon.png')} />
            <div style={{ fontSize: 12, color: 'white', fontSize: 20, marginBottom: 10 }}>为养生康养提供赋能</div>
            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 12, color: 'white', fontSize: 14 }}>恒透灸提倡科学养生，普及养生常识，提高健康意</div>
              <div style={{ padding: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <nav>
                  <Link to={{
                    pathname: '/audit/user',
                  }}>用户协议</Link>
                  <Link to={{
                    pathname: '/audit/secret',
                  }}>隐私政策</Link>
                </nav>
                <div style={{ color: '#B2B2B2', fontSize: 10, padding: 5 }}>恒透灸 成都集善健康科技有限公司 </div>
                <div style={{ color: '#B2B2B2', fontSize: 10, padding: 5 }}>蜀ICP备15012003号-26</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;

const styles = {
  allPage: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  topPage: {
    backgroundColor: '#EFF0F4',
    width: '100%',
    flex: 1,
  },
  bottomPage: {
    backgroundColor: '#31343E',
    width: '100%',
    flex: 1,
  },
  shu: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  shuflex: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: 30,
  },
};

